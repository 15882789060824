<template>
  <!--begin::FaresTender index-->
  <div>
    <div class="manager-title">
      <div>
        {{
          $t("FARES.TENDER.TITLE_INDEX_FROM_SENDER") + ' - "' + SenderName + '"'
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("FARES.COMMON.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-3 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg
              v-if="ShowFilters"
              src="/media/icons/filter-alt-1.svg"
            />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-3 py-6 box-filters">
        <div class="col-lg-3">
          <label>
            {{ $t("TENDER_ROUTES.ORIGIN") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByOriginProvinceID"
            @change="searchFares(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-3">
          <label>
            {{ $t("TENDER_ROUTES.DESTINATION") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByDestinationProvinceID"
            @change="searchFares(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-3">
          <label>
            {{ $t("TENDER_ROUTES.STATUS") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByActive"
            @change="searchFares(false)"
          >
            <option value="-2" class="text-primary">
              &nbsp;
            </option>
            <option
              v-for="a in [-1, 0, 1]"
              :key="'Active_' + a"
              :value="a"
              class="text-primary"
            >
              {{ $t("TENDER_ROUTES.ACTIVE." + a) }}
            </option>
          </select>
        </div>

        <div class="col-lg-3 pt-7 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ getProvinceName(item.TenderRoute.OriginProvinceID) }}
          </td>
          <td v-html="formatAddressData(item.TenderRoute.OriginAddress)"></td>
          <td>
            {{ getProvinceName(item.TenderRoute.DestinationProvinceID) }}
          </td>
          <td
            v-html="formatAddressData(item.TenderRoute.DestinationAddress)"
          ></td>
          <td>
            {{
              item.Active === -1
                ? " - - "
                : formatPrice(item.PricePerTruck, item.Currency)
            }}
          </td>
          <td>
            {{
              item.Active === -1
                ? " - - "
                : formatPrice(item.PricePerUnit, item.Currency)
            }}
          </td>
          <td>
            <span
              class="white-box border-2 p-3 font-size-sm text-uppercase"
              :class="formatStatusClass(item.Active)"
              style="white-space: nowrap"
            >
              {{ $t("TENDER_ROUTES.ACTIVE." + item.Active) }}
            </span>
          </td>
          <td>
            <span
              class="action-icon"
              :class="[item.Active === -1 ? 'bg-primary' : '']"
              @click="
                $router.push(
                  '/manager/fares/tender/fromsender/' +
                    SenderID +
                    '/' +
                    item.FareTenderRouteID
                )
              "
            >
              <inline-svg
                v-if="item.Active === -1"
                src="/media/icons/add.svg"
              />
              <inline-svg v-else src="/media/icons/edit-alt-2-primary.svg" />
            </span>

            <span class="action-icon ml-4" @click="deleteFare(item)">
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchFares(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->

    <!--begin::Back to full search-->
    <div class="row mt-6">
      <button
        class="btn btn-pill btn-white button-form button-form-primary mr-9"
        @click="goBackToFullSearch"
      >
        <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
        {{ $t("GENERIC.BACK") }}
      </button>
    </div>
    <!--end::Back to full search-->
  </div>
  <!--end::FaresTender index-->
</template>

<script>
import Swal from "sweetalert2";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import FareTenderRouteService from "@/core/services/api/v2/faretenderroute.service";
import ProvinceService from "@/core/services/api/v2/province.service";

export default {
  name: "FaresTenderIndex",
  data() {
    return {
      UserLang: "",
      SenderID: 0,
      SenderName: "",
      TableHeaders: [
        {
          text: this.$t("TENDER_ROUTES.ORIGIN"),
          value: "TenderRoute.OriginProvinceID",
          width: 120
        },
        {
          text: this.$t("TENDER_ROUTES.ORIGIN_ADDRESS"),
          value: "TenderRoute.OriginAddress.Nick",
          width: 240
        },
        {
          text: this.$t("TENDER_ROUTES.DESTINATION"),
          value: "TenderRoute.DestinationProvinceID",
          width: 120
        },
        {
          text: this.$t("TENDER_ROUTES.DESTINATION_ADDRESS"),
          value: "TenderRoute.DestinationAddress.Nick",
          width: 240
        },
        {
          text: this.$t("FARES.COMMON.PRICE_PER_TRUCK_SHORT"),
          value: "PricePerTruck"
        },
        {
          text: this.$t("FARES.COMMON.PRICE_PER_UNIT_SHORT"),
          value: "PricePerUnit"
        },
        {
          text: this.$t("TENDER_ROUTES.STATUS"),
          value: "Active"
        },
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
      ],
      options: {},
      ProvinceOptions: [],
      ProvinceNames: [],
      SortBy: "",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByOriginProvinceID: 0,
      FilterByDestinationProvinceID: 0,
      FilterByActive: -2,
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  mounted() {
    this.UserLang = i18nService.getActiveLanguage();

    this.SenderID = parseInt(this.$route.params.senderid);
    if (!this.SenderID) {
      this.goBackToFullSearch();
    } else {
      this.SenderName = window.sessionStorage.getItem(
        "FareTenderRouteSenderName",
        ""
      );

      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.loadProvinceOptions();
    }
  },

  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceOptions.push({ ProvinceID: 0, Name: " " });

      this.ProvinceNames = [];
      this.ProvinceNames[0] = " ";

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchFares(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(FareTenderRouteService.getCachedFilters()).forEach(f => {
        this["FilterBy" + f[0]] = f[1];

        if (!anyFilterNotEmpty) {
          anyFilterNotEmpty = parseInt(f[1]) > 0;
        }
      });

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchFares(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      FareTenderRouteService.searchToMyTransporterFromSender(
        this.SenderID,
        this.SearchText,
        this.FilterByOriginProvinceID,
        this.FilterByDestinationProvinceID,
        this.FilterByActive,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.FareTenderRoutes;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchFares(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchFares(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchFares(false);
    },

    changeItemsCurrentPage() {
      this.searchFares(true);
    },

    cleanFilters() {
      this.FilterByOriginProvinceID = 0;
      this.FilterByDestinationProvinceID = 0;
      this.FilterByActive = -2;
      this.ShowFilters = false;
      this.searchFares(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchFares(false);
      }, 600);
    },

    getProvinceName(pID) {
      let name = this.ProvinceNames[pID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    formatAddressData(ad) {
      let text = " - ";
      if (ad) {
        text =
          "<strong>" +
          ad.TradeName +
          "</strong><br />" +
          ad.Street +
          "<br />" +
          ad.PostalCode +
          " - " +
          ad.City;
      }

      return text;
    },

    formatPrice(price, currency) {
      if (price.length === 0) {
        return "";
      } else {
        let price2 = price.toFixed(2);
        if (this.UserLang !== "en") {
          price2 = price2.replace(".", ",");
        }

        return price2 + " " + currency;
      }
    },

    formatStatusClass(itemActive) {
      if (itemActive === 1) {
        // Fully OK
        return "border-tertiary text-tertiary";
      } else if (itemActive === -1) {
        // Waiting for price
        return "border-danger text-danger";
      } else {
        return "";
      }
    },

    deleteFare(item) {
      let swalTitle =
        this.$i18n.t("GENERIC.DELETE_ALERT") +
        "\n" +
        this.getProvinceName(item.TenderRoute.OriginProvinceID);

      if (item.TenderRoute.OriginAddress) {
        swalTitle += " - " + item.TenderRoute.OriginAddress.TradeName;
      }

      swalTitle +=
        "\n" + this.getProvinceName(item.TenderRoute.DestinationProvinceID);

      if (item.TenderRoute.DestinationAddress) {
        swalTitle += " - " + item.TenderRoute.DestinationAddress.TradeName;
      }

      Swal.fire({
        title: swalTitle,
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          FareTenderRouteService.deleteProfile(
            this.SenderID,
            item.FareTenderRouteID
          ).then(statusCode => {
            if (statusCode >= 400) {
              this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              Swal.fire({
                text: this.$i18n.t("GENERIC.DELETE_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              this.searchFares(true);
            }
          });
        }
      });
    },

    goBackToFullSearch() {
      this.$router.push("/manager/fares?ActiveTab=tender");
    }
  }
};
</script>
